import React from 'react';
import PropTypes from 'prop-types';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import LazyImage from 'gatsby-core-theme/src/hooks/lazy-image';
import Button from 'gatsby-core-theme/src/components/atoms/button/button';
import { imagePrettyUrl, getAltText, getExtraField } from 'gatsby-core-theme/src/helpers/getters';
import styles from './template-one.module.scss';

export default function TemplateOne({ title, description, extraFields, imageObj }) {
  const link = getExtraField(extraFields, 'link');

  return (
    <div className={styles.templateOne}>
      <div className={styles.headerContainer}>
        <h1>{title}</h1>
        {description && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.description}
          />
        )}
        {link && (
          <Button
            to={link}
            buttonType="secondary"
            gtmClass="game-card-gtm btn-cta"
            btnText={link}
            icon={<FaArrowRight title="Right-pointing Arrow Icon" />}
          />
        )}
      </div>
      {imageObj && (
        <LazyImage
          src={imagePrettyUrl(imageObj.filename, 356, 343)}
          alt={getAltText(imageObj, imageObj.title)}
          width={356}
          height={343}
          loading="eager"
          className={styles.image}
        />
      )}
    </div>
  );
}

TemplateOne.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageObj: PropTypes.shape({
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    filename: PropTypes.string,
    title: PropTypes.string,
  }),
  extraFields: PropTypes.shape({
    link: PropTypes.string,
  }),
};
